<template>
  <div class="whole-table">
    <el-table
      v-loading="config.loading"
      :data="tableData"
      border
      highlight-current-row
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column align="center" prop="code" label="编号" width="150"> </el-table-column>
      <el-table-column align="center" prop="date" label="日期" width="100"> </el-table-column>
      <el-table-column align="center" prop="cangku" label="出入库房" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiao" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige" label="材料规格" width="100"> </el-table-column>
      <el-table-column align="center" prop="total_amount" label="总数量" width="100"> </el-table-column>
      <el-table-column align="center" prop="ingongdi" label="到工地" width="100"> </el-table-column>
      <el-table-column align="center" prop="onroad" label="运输中" width="100"> </el-table-column>
      <el-table-column align="center" prop="left_amount" label="剩余未发出" width="100"> </el-table-column>
    </el-table>
  </div>
</template>

<script>
// import utils from '@/assets/js/utils'

export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },

  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    }
  },
  created() {
    console.log('this.tabledata:', this.tableData)
  }
}
</script>
